<template>
    <div class="right-menu shipping-right">

        <subHeader pageName="PIXEL MANAGEMENT" />

        <tutorialsAction moduleName="PIXEL MANAGEMENT"/>

        <div class="paymentSection">
            <div class="db-container">
                <div class="paymentMainBlock">

                    <!-- Google tag manager starts here -->
                    <div class="paymentMethodWrap" id='google-tag-manager-step'>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="paymentHeading">
                                    <h4>Google Tag Manager</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="actionSwitch">
                                    <label class="switch" id="google-tag-manager-status">
                                        <input 
                                            type="checkbox" 
                                            v-model="googleTagManagerPixel.isActive" 
                                            :disabled="tourIsActive" 
                                            @change="toggleProceedableHandle('google-tag-manager')"
                                        >
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ValidationObserver ref="googleTagManagerObserver">
                            <div class="bankingFormField" :class="googleTagManagerPixel.isActive ? 'expandForm' : ''" id="google-tag-manager-form">
                                <form @submit.prevent="validate('google-tag-manager')">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <ValidationProvider name="container id" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="google-tag-manager-container-id">Container ID <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="google-tag-manager-container-id"
                                                        placeholder="Container ID" 
                                                        v-model="googleTagManagerData.container_id" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="paymentSaveBtn">
                                                <loaderBtn v-if="googleTagManagerLoader"/>
                                                <Button id="google-tag-manager-save-btn" :disabled="tourIsActive" v-else>Save</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ValidationObserver>
                    </div>
                    <!-- Google tag manager ends here -->

                    <!-- Meta pixel starts here -->
                    <div class="paymentMethodWrap" id='meta-pixel-step'>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="paymentHeading">
                                    <h4>Meta Pixel</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="actionSwitch">
                                    <label class="switch" id="meta-pixel-status">
                                        <input 
                                            type="checkbox" 
                                            v-model="metaPixel.isActive" 
                                            :disabled="tourIsActive" 
                                            @change="toggleProceedableHandle('meta-pixel')"
                                        >
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ValidationObserver ref="metaPixelObserver">
                            <div class="bankingFormField" :class="metaPixel.isActive ? 'expandForm' : ''" id="meta-pixel-form">
                                <form @submit.prevent="validate('meta-pixel')">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <ValidationProvider name="pixel id" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="meta-pixel-pixel-id">Pixel ID <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="meta-pixel-pixel-id" 
                                                        placeholder="Meta pixel ID"
                                                        v-model="metaPixelData.meta_pixel_id" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="paymentSaveBtn">
                                                <loaderBtn v-if="metaPixelLoader"/>
                                                <Button id="meta-pixel-save-btn" :disabled="tourIsActive" v-else>Save</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ValidationObserver>
                    </div>
                    <!-- Meta pixel ends here -->

                    <!-- Google analytics starts here -->
                    <div class="paymentMethodWrap" id='google-analytics-step'>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="paymentHeading">
                                    <h4>Google Analytics</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="actionSwitch">
                                    <label class="switch" id="google-analytics-status">
                                        <input 
                                            type="checkbox" 
                                            v-model="googleAnalytics.isActive" 
                                            :disabled="tourIsActive" 
                                            @change="toggleProceedableHandle('google-analytics')"
                                        >
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ValidationObserver ref="googleAnalyticsObserver">
                            <div class="bankingFormField" :class="googleAnalytics.isActive ? 'expandForm' : ''" id="google-analytics-form">
                                <form @submit.prevent="validate('google-analytics')">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <ValidationProvider name="tracking id" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="google-analytics-tracking-id">Tracking ID <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="google-analytics-tracking-id"
                                                        placeholder="Tracking ID" 
                                                        v-model="googleAnalyticsData.tracking_id" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="paymentSaveBtn">
                                                <loaderBtn v-if="GoogleAnalyticsLoader"/>
                                                <Button id="google-analytics-save-btn" :disabled="tourIsActive" v-else>Save</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ValidationObserver>
                    </div>
                    <!-- Google analytics ends here -->

                    <!-- Clarity snippet starts here -->
                    <div class="paymentMethodWrap" id='ms-clarity-step'>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="paymentHeading">
                                    <h4>Microsoft Clarity</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="actionSwitch">
                                    <label class="switch" id="ms-clarity-status">
                                        <input 
                                            type="checkbox" 
                                            v-model="msClarity.isActive" 
                                            :disabled="tourIsActive" 
                                            @change="toggleProceedableHandle('ms-clarity')"
                                        >
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ValidationObserver ref="msClarityObserver">
                            <div class="bankingFormField" :class="msClarity.isActive ? 'expandForm' : ''" id="ms-clarity-form">
                                <form @submit.prevent="validate('ms-clarity')">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <ValidationProvider name="project id" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="ms-clarity-project-id">Project ID <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="ms-clarity-project-id"
                                                        placeholder="Project ID" 
                                                        v-model="msClarityData.project_id" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="paymentSaveBtn">
                                                <loaderBtn v-if="msClarityLoader"/>
                                                <Button id="ms-clarity-save-btn" :disabled="tourIsActive" v-else>Save</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ValidationObserver>
                    </div>
                    <!-- Clarity snippet ends here -->

                </div>
            </div>
        </div>

        <!-- vue tour -->
        <v-tour name="pixelManagementTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import { MessageBox, Message, Loading } from "element-ui";
import _ from 'lodash';
import { mapGetters } from 'vuex';
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    metaInfo(){
        return{
            title: 'Marketing | Pixel Management | YeetCommerce',
        };
    },
    data(){
        return{
            
            //google tag manager start
            googleTagManagerPixel: {},
            tempGoogleTagManagerPixel: {},
            googleTagManagerData: {}, 
            googleTagManagerLoader: false,
            //google tag manager end

            //meta pixel start
            metaPixel: {},
            tempMetaPixel: {},
            metaPixelData: {},
            metaPixelLoader: false,
            //meta pixel end

            //google analytics start
            googleAnalytics: {},
            tempGoogleAnalytics: {},
            googleAnalyticsData: {},
            GoogleAnalyticsLoader: false,
            //google analytics end

            //clarity snippet start
            msClarity: {},
            tempMsClarity: {},
            msClarityData: {},
            msClarityLoader: false,
            //clarity snippet end

            //vue tour
            tourOptions:{
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks:{
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: true
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: true
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: true
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: true
                    }
                },
                //google tag manager steps
                {
                    target: '#google-tag-manager-step',
                    content: `<strong>Google Tag Manager</strong><br>Google tag manager configuration is available for the user.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:"bottom"
                    }
                },
                {
                    target: '#google-tag-manager-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the google tag manager can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:"bottom"
                    }
                },
                {
                    target: '#google-tag-manager-container-id',
                    content: `<strong>Google Tag Manager Container ID</strong><br>GTM container ID can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:"bottom"
                    }
                },
                {
                    target: '#google-tag-manager-save-btn',
                    content: `<strong>Save Details</strong><br>Google tag manager details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:"bottom"
                    }
                },
                //meta pixel steps
                {
                    target: '#meta-pixel-step',
                    content: `<strong>Meta Pixel</strong><br>Meta pixel configuration is available for the user.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#meta-pixel-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the Meta pixel can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#meta-pixel-pixel-id',
                    content: `<strong>Google Tag Manager Container ID</strong><br>GTM container ID can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#meta-pixel-save-btn',
                    content: `<strong>Save Details</strong><br>Meta pixel details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                //google analytics
                {
                    target: '#google-analytics-step',
                    content: `<strong>Google Analytics</strong><br>Google analytics configuration is available for the user.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#google-analytics-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the Google analytics can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#google-analytics-tracking-id',
                    content: `<strong>Google Analytics Tracking ID</strong><br>Google Analytics tracking ID can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#google-analytics-save-btn',
                    content: `<strong>Save Details</strong><br>Google Analytics details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                //microsoft clarity
                {
                    target: '#ms-clarity-step',
                    content: `<strong>Microsoft Clarity</strong><br>Microsoft Clarity configuration is available for the user.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:"bottom"
                    }
                },
                {
                    target: '#ms-clarity-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the Microsoft Clarity can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:"bottom"
                    }
                },
                {
                    target: '#ms-clarity-project-id',
                    content: `<strong>Microsoft Clarity Project ID</strong><br>Microsoft Clarity project ID can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:"bottom"
                    }
                },
                {
                    target: '#ms-clarity-save-btn',
                    content: `<strong>Save Details</strong><br>Microsoft Clarity details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:"bottom"
                    }
                },
            ],
            tourIsActive:false
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        loaderBtn
    },
    computed:{
    },
    methods:{

        validate(form){

            if(form == 'google-tag-manager'){

                this.$refs.googleTagManagerObserver.validate().then((success) => {

                    if(!success){

                        const errors = Object.entries(this.$refs.googleTagManagerObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.googleTagManagerObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                    }else{

                        this.updatePixelHandle(form);

                    }

                });

            }

            if(form == 'meta-pixel'){
                
                this.$refs.metaPixelObserver.validate().then((success) => {

                    if(!success){

                        const errors = Object.entries(this.$refs.metaPixelObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.metaPixelObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                    }else{

                        this.updatePixelHandle(form);

                    }

                });

            }

            if(form == 'google-analytics'){

                this.$refs.googleAnalyticsObserver.validate().then((success) => {

                    if(!success){

                        const errors = Object.entries(this.$refs.googleAnalyticsObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.googleAnalyticsObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                    }else{

                        this.updatePixelHandle(form);

                    }

                });

            }

            if(form == 'ms-clarity'){

                this.$refs.msClarityObserver.validate().then((success) => {

                    if(!success){

                        const errors = Object.entries(this.$refs.msClarityObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.msClarityObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                    }else{

                        this.updatePixelHandle(form);

                    }

                });

            }

        },

        async updatePixelHandle(form){

            let formData = new FormData();

            if(form == 'google-tag-manager'){

                formData.append('id',this.googleTagManagerPixel.id);
                formData.append('container_id',this.googleTagManagerData.container_id);
                formData.append('isActive',this.googleTagManagerPixel.isActive ? 1 : 0);

                this.googleTagManagerLoader = true;

            }

            if(form == 'meta-pixel'){

                formData.append('id',this.metaPixel.id);
                formData.append('meta_pixel_id',this.metaPixelData.meta_pixel_id);
                formData.append('isActive',this.metaPixel.isActive ? 1 : 0);

                this.metaPixelLoader = true;

            }

            if(form == 'google-analytics'){

                formData.append('id',this.googleAnalytics.id);
                formData.append('tracking_id',this.googleAnalyticsData.tracking_id);
                formData.append('isActive',this.googleAnalytics.isActive ? 1 : 0);

                this.GoogleAnalyticsLoader = true;

            }

            if(form == 'ms-clarity'){

                formData.append('id',this.msClarity.id);
                formData.append('project_id',this.msClarityData.project_id);
                formData.append('isActive',this.msClarity.isActive ? 1 : 0);

                this.msClarityLoader = true;

            }

            let loader = Loading.service({
                text: "The pixel management tool is being updated. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/pixel-management/update',formData);
                if(res.data.status_code == '1531' || res.data.status_code == '1532' || 
                res.data.status_code == '1533' || res.data.status_code == '1534'){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                }
            }catch(error){

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                loader.close();

                if(form == 'google-tag-manager'){
                
                    this.googleTagManagerLoader = false;

                }else if(form == 'meta-pixel'){

                    this.metaPixelLoader = false;

                }else if(form == 'google-analytics'){

                    this.GoogleAnalyticsLoader = false;

                }else if(form == 'ms-clarity'){

                    this.msClarityLoader = false;

                }

                this.loadPixelManagementData();

            }

        },

        async toggleProceedableHandle(form){
            
            if(form == 'google-tag-manager'){

                if(this.googleTagManagerPixel.isActive){

                    //validating the google tag manager form
                    this.$refs.googleTagManagerObserver.validate().then(async (success) => {

                        if(!success){

                            const errors = Object.entries(this.$refs.googleTagManagerObserver.errors)
                            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                            this.$refs.googleTagManagerObserver.refs[errors[0]["key"]].$el.scrollIntoView({behavior: "smooth", block: "center",});

                            this.$notify({
                                type: 'info',
                                title: 'Information Missing',
                                message: "Kindly enter Google Tag Manager container ID and save it to enable it.",
                            });

                            this.$refs.googleTagManagerObserver.reset();

                        }else{

                            if(this.googleTagManagerPixel.isActive != this.tempGoogleTagManagerPixel.isActive){

                                this.pixelStatusToggle(form);

                            }

                        }

                    });

                }else{

                    if(this.googleTagManagerPixel.isActive != this.tempGoogleTagManagerPixel.isActive){

                        this.pixelStatusToggle(form);

                    }

                }

            }

            if(form == 'meta-pixel'){

                if(this.metaPixel.isActive){

                    //validating the meta pixel form
                    this.$refs.metaPixelObserver.validate().then(async (success) => {

                        if(!success){

                            const errors = Object.entries(this.$refs.metaPixelObserver.errors)
                            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                            this.$refs.metaPixelObserver.refs[errors[0]["key"]].$el.scrollIntoView({behavior: "smooth", block: "center",});

                            this.$notify({
                                type: 'info',
                                title: 'Information Missing',
                                message: "Kindly enter Meta Pixel ID and save it to enable it.",
                            });

                            this.$refs.metaPixelObserver.reset();

                        }else{
                            
                            if(this.metaPixel.isActive != this.tempMetaPixel.isActive){

                                this.pixelStatusToggle(form)

                            }

                        }

                    });

                }else{

                    if(this.metaPixel.isActive != this.tempMetaPixel.isActive){

                        this.pixelStatusToggle(form)

                    }

                }

            }

            if(form == 'google-analytics'){

                if(this.googleAnalytics.isActive){

                    //validating the google tag manager form
                    this.$refs.googleAnalyticsObserver.validate().then(async (success) => {

                        if(!success){

                            const errors = Object.entries(this.$refs.googleAnalyticsObserver.errors)
                            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                            this.$refs.googleAnalyticsObserver.refs[errors[0]["key"]].$el.scrollIntoView({behavior: "smooth", block: "center",});

                            this.$notify({
                                type: 'info',
                                title: 'Information Missing',
                                message: "Kindly enter Google Analytics tracking ID and save it to enable it.",
                            });

                            this.$refs.googleAnalyticsObserver.reset();

                        }else{

                            if(this.googleAnalytics.isActive != this.tempGoogleAnalytics.isActive){

                                this.pixelStatusToggle(form);

                            }

                        }

                    });

                }else{

                    if(this.googleAnalytics.isActive != this.tempGoogleAnalytics.isActive){

                        this.pixelStatusToggle(form);

                    }

                }

            }

            if(form == 'ms-clarity'){

                if(this.msClarity.isActive){

                    //validating the google tag manager form
                    this.$refs.msClarityObserver.validate().then(async (success) => {

                        if(!success){

                            const errors = Object.entries(this.$refs.msClarityObserver.errors)
                            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                            this.$refs.msClarityObserver.refs[errors[0]["key"]].$el.scrollIntoView({behavior: "smooth", block: "center",});

                            this.$notify({
                                type: 'info',
                                title: 'Information Missing',
                                message: "Kindly enter Microsoft Clarity project ID and save it to enable it.",
                            });

                            this.$refs.msClarityObserver.reset();

                        }else{

                            if(this.msClarity.isActive != this.tempMsClarity.isActive){

                                this.pixelStatusToggle(form);

                            }

                        }

                    });

                }else{

                    if(this.msClarity.isActive != this.tempMsClarity.isActive){

                        this.pixelStatusToggle(form);

                    }

                }

            }

        },
        async pixelStatusToggle(form){

            let formData = new FormData();

            if(form == 'google-tag-manager'){
            
                formData.append('id',this.googleTagManagerPixel.id);
                formData.append('isActive',this.googleTagManagerPixel.isActive ? 1 : 0);

            }

            if(form == 'meta-pixel'){

                formData.append('id',this.metaPixel.id);
                formData.append('isActive',this.metaPixel.isActive ? 1 : 0);

            }
            
            if(form == 'google-analytics'){

                formData.append('id',this.googleAnalytics.id);
                formData.append('isActive',this.googleAnalytics.isActive ? 1 : 0);

            }

            if(form == 'ms-clarity'){

                formData.append('id',this.msClarity.id);
                formData.append('isActive',this.msClarity.isActive ? 1 : 0);
  
            }

            let loader = Loading.service({
                text: "The pixel management tool is undergoing status update. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/pixel-management/toggle',formData)
                if(res.data.status_code == '1600' || res.data.status_code == '1601'){
                    
                    if(form == 'google-tag-manager'){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: `Google tag manager has been ${res.data.message}`,
                        });

                    }else if(form == 'meta-pixel'){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: `Meta pixel has been ${res.data.message}`,
                        });

                    }else if(form == 'google-anayltics'){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: `Google analytics has been ${res.data.message}`,
                        });

                    }else if(form == 'ms-clarity'){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: `Microsoft clarity has been ${res.data.message}`,
                        });

                    }

                }
            }catch(error){

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                loader.close();

                this.loadPixelManagementData();

            }

        },

        async loadPixelManagementData(){
            
            let loader = Loading.service({
                text: "Fetching pixel management tools. Please wait!",
                fullscreen: true,
            });

            try{

                let res = await this.$axios.get('/pixel-management/all');
                if(res.data.status_code == "1530"){

                    res.data.pixel_management_records.forEach((pixelData) => {

                        if(pixelData.platform == 'Google Tag Manager'){

                            this.googleTagManagerPixel = pixelData;

                            this.tempGoogleTagManagerPixel = _.cloneDeep(this.googleTagManagerPixel);

                            let pixelManagementData = JSON.parse(this.googleTagManagerPixel.data);

                            this.googleTagManagerData = pixelManagementData;

                        }

                        if(pixelData.platform == 'Meta Pixel'){

                            this.metaPixel = pixelData;

                            this.tempMetaPixel = _.cloneDeep(this.metaPixel);

                            let pixelManagementData = JSON.parse(this.metaPixel.data);
                            
                            this.metaPixelData = pixelManagementData;

                        }

                        if(pixelData.platform == 'Google Analytics'){

                            this.googleAnalytics = pixelData;

                            this.tempGoogleAnalytics = _.cloneDeep(this.googleAnalytics);

                            let pixelManagementData = JSON.parse(this.googleAnalytics.data);

                            this.googleAnalyticsData = pixelManagementData

                        }

                        if(pixelData.platform == 'Microsoft Clarity'){

                            this.msClarity = pixelData;

                            this.tempMsClarity = _.cloneDeep(this.msClarity);

                            let pixelManagementData = JSON.parse(this.msClarity.data);

                            this.msClarityData = pixelManagementData

                        }

                    });

                }

            }catch(error){
                
                this.$message({
                    type: 'Error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                loader.close();

            }

        },

        //tour methods
        startPixelManagementTour(){

            this.$tours.pixelManagementTour.start();

            this.tourIsActive = true;

            this.googleTagManagerPixel.isActive = false;
            this.metaPixel.isActive = false;
            this.googleAnalytics.isActive = false;
            this.msClarity.isActive = false;

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 6){

                this.googleTagManagerPixel.isActive = true;

            }

            if(currentStep + 1 == 10){

                this.metaPixel.isActive = true;
                
            }

            if(currentStep + 1 == 14){

                this.googleAnalytics.isActive = true;

            }

            if(currentStep + 1 == 18){

                this.msClarity.isActive = true;

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 6){

                this.googleTagManagerPixel.isActive = false;

            }

            if(currentStep == 10){

                this.metaPixel.isActive = false;

            }

            if(currentStep == 14){

                this.googleAnalytics.isActive = false;

            }

            if(currentStep == 18){

                this.msClarity.isActive = false;

            }

        },
        handleTourStop(){

            if(this.$tours.pixelManagementTour && this.$tours.pixelManagementTour.currentStep != -1){
                
                this.loadPixelManagementData();

                this.tourIsActive = false;

            }

        },
        handleTourFinish(){

            this.loadPixelManagementData();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            if(this.$tours.pixelManagementTour && this.$tours.pixelManagementTour.currentStep != -1){
                
                this.loadPixelManagementData();

                this.tourIsActive = false;

            }

        },

    },
    mounted(){

        //loading the pixel management data
        this.loadPixelManagementData();

    },
    beforeMount(){

    },
}
</script>

<style scoped>
    .bankingFormField .row{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
</style>